import ReactDatepicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de";

// register more locales here, if needed...
registerLocale("de", de);

export * from "react-datepicker";

// `export default from…` is still experimental
export default ReactDatepicker;
